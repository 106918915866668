<template>
  <div>
    <q-form ref="giveEditForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant
              :disabled="true"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="param.equipmentRent.plantCd"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              :disabled="true"
              default="today"
              name="requestDate"
              label="신청일"
              v-model="param.equipmentRent.requestDate"
            ></c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-text
              :disabled="true"
              :editable="editable"
              type="dept_user"
              label='신청자'
              name="regUserId"
              v-model="request"
            />
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="tableGive"
      title="장비 대여/반납 목록"
      :editable="editable && !disabled"
      :columns="gridRentReturn.columns"
      :data="param.equipmentRent.rentReturnItems"
      :gridHeight="gridHeight"
      :merge="gridRentReturn.merge"
      :isFullScreen="false"
      :columnSetting="false"
      :filtering="false"
      :usePaging="false"
      :isExcelDown="false"
    >
      <!-- selection="multiple"
      rowKey="heaClosedSpaceEquipmentReturnItemId" -->
      <!-- checkDisableColumn="rowDisabled" -->
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <!-- <c-btn 
            v-show="editable && !disabled" 
            label="LBLADD" 
            icon="add" 
            @btnClicked="addRequestEquips" />
          <c-btn 
            v-show="editable && !disabled" 
            label="LBLREMOVE" 
            icon="remove" 
            @btnClicked="removeGive" /> -->
          <c-btn 
            v-show="editable && !disabled" 
            :url="saveUrl"
            :isSubmit="isSave"
            :param="param.equipmentRent.rentReturnItems"
            mappingType="PUT"
            icon="save" 
            label="LBLSAVE" 
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
          <c-btn 
            v-show="editable && !disabled" 
            :url="finishUrl"
            :isSubmit="isFinish"
            :param="param.equipmentRent"
            mappingType="PUT"
            icon="check" 
            label="종료" 
            @beforeAction="finishInfo"
            @btnCallback="finishCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='rent'">
          <c-btn 
            v-if="editable && !disabled && props.row.rentFlag !== 'Y'" 
            label="대여하기" 
            icon="logout" 
            @btnClicked="rentInfo(props.row)" />
          <font v-else class="text-primary" style="font-size:1em;font-weight:500;">
            {{ props.row.rentDtStr }}<br/>대여완료
          </font>
        </template>
        <template v-else-if="col.name==='return'">
          <c-btn 
            v-if="editable && !disabled && props.row.rentFlag === 'Y' && props.row.returnFlag !== 'Y'" 
            label="반납하기" 
            icon="login" 
            @btnClicked="returnInfo(props.row)" />
          <font v-else-if="props.row.returnFlag === 'Y'" class="text-primary" style="font-size:1em;font-weight:500;">
            {{ props.row.returnDtStr }}<br/>반납완료
          </font>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'closedSpaceEquipGive',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopEquipmentRentId: '',
        equipmentRent: {
          sopEquipmentRentId: '',  // 작업장비 대여 일련번호
          plantCd: '',  // 사업장 코드
          equipRentStatusCd: '',  // 진행단계
          requestDate: '',  // 신청일
          sopWorkPermitId: null,  // 작업허가서 일련번호
          permitNo: '',
          regUserId: '',  // 등록자 ID
          regUserName: '',  // 등록자 ID
          regUserDeptName: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          requestItems: [],
          deleteRequestItems: [],
          rentReturnItems: [],
        },
        isOld: false,
      }),
    },
    height: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      gridRentReturn: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '장비종류',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '장비명',
            style: 'width:150px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'requestCount',
            field: 'requestCount',
            label: '신청수량',
            style: 'width:90px',
            align: 'right',
            sortable: false,
          },
          {
            name: 'rentCount',
            field: 'rentCount',
            label: '대여수량',
            style: 'width:90px',
            align: 'right',
            type: 'number',
            sortable: false,
            disableTarget: 'rentFlag',
            disableCon: 'N'
          },
          {
            name: 'unitName',
            field: 'unitName',
            label: '단위',
            style: 'width:60px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'rentPeriod',
            field: 'rentPeriod',
            label: '대여기간',
            style: 'width:150px',
            align: 'center',
            range: true,
            type: 'date',
            sortable: false,
            disableTarget: 'rentFlag',
            disableCon: 'N'
          },
          {
            name: 'rent',
            field: 'rent',
            label: '대여',
            style: 'width:120px',
            align: 'center',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'return',
            field: 'return',
            label: '반납',
            style: 'width:120px',
            align: 'center',
            type: 'custom',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: 'LBLREMARK',
            align: 'left',
            type: 'text',
            style: 'width:250px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      saveType: 'POST',
      isSave: false,
      isFinish: false,
      listUrl: '',
      saveUrl: '',
      finishUrl: '',
      deleteUrl: '',
      rentUrl: '',
      returnUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.param.equipmentRent.equipRentStatusCd === 'CSE0000003'
    },
    gridHeight() {
      let _height = this.height;
      if (_height) {
        _height = String(Number(this.$_.replace(_height, 'px', '')) - 150) + 'px'
      } else {
        _height = '600px'
      }
      return _height;
    },
    request() {
      return this.param.equipmentRent.regUserDeptName ? `${this.param.equipmentRent.regUserDeptName} / ${this.param.equipmentRent.regUserName}` : `${this.param.equipmentRent.regUserName}`
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.cse.rentReturn.list.url;
      this.saveUrl = transactionConfig.hea.cse.rentReturn.save.url;
      this.finishUrl = transactionConfig.hea.cse.gives.finish.url;
      this.deleteUrl = transactionConfig.hea.cse.rentReturn.delete.url;
      this.rentUrl = transactionConfig.hea.cse.rentReturn.rent.url;
      this.returnUrl = transactionConfig.hea.cse.rentReturn.return.url;
      // code setting
      // list setting
    },
    getList() {
      if (this.param.isOld) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET'; 
        this.$http.param = {
          sopEquipmentRentId: this.param.sopEquipmentRentId,
        } 
        this.$http.request((_result) => {
          this.$set(this.param.equipmentRent, 'rentReturnItems', _result.data)
        
          if (this.param.equipmentRent.rentReturnItems && this.param.equipmentRent.rentReturnItems.length > 0) {
            this.$_.forEach(this.param.equipmentRent.rentReturnItems, item => {
              item.rentPeriod = [item.rentStartDate, item.rentEndDate]
            })
          }
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    // removeGive() {
    //   let selectData = this.$refs["tableGive"].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit("ALERT", {
    //       title: "LBLGUIDE", // 안내
    //       message: "MSGNOITEMS", // 선택된 항목이 없습니다.
    //       type: "warning", // success / info / warning / error
    //     });
    //   } else {
    //     this.$http.url = this.deleteUrl;
    //     this.$http.type = 'DELETE';
    //     this.$http.param = {
    //       data: Object.values(selectData),
    //     };
    //     this.$http.request(() => {
    //       window.getApp.$emit('APP_REQUEST_SUCCESS');
          
    //       this.$_.forEach(selectData, item => {
    //         this.param.equipmentRent.rentReturnItems = this.$_.reject(this.param.equipmentRent.rentReturnItems, item);
    //       })
    //       this.$refs['tableGive'].$refs['compo-table'].clearSelection();
    //     },);
    //   }
    // },
    // addRequestEquips() {
    //   this.popupOptions.title = '대여장비 신청 현황';
    //   this.popupOptions.param = {
    //     type: 'multiple',
    //     sopEquipmentRentId: this.param.equipmentRent.sopEquipmentRentId,
    //   };
    //   this.popupOptions.target = () => import(`${'./closedSpaceEquipApplyPop.vue'}`);
    //   this.popupOptions.width = '60%';
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeApplyEquipPopup;
    // },
    // closeApplyEquipPopup(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     this.$_.forEach(data, item => {
    //       if (this.$_.findIndex(this.param.equipmentRent.rentReturnItems, { heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId }) === -1) {
    //         this.param.equipmentRent.rentReturnItems.push({
    //           sopEquipmentRentId: this.param.sopEquipmentRentId,  // 작업장비 대여 일련번호
    //           sopEquipmentRentReturnItemId: uid(),  // 대여/반납 장비 목록 일련번호
    //           heaClosedSpaceEquipmentId: item.heaClosedSpaceEquipmentId,  // 대여 장비 일련번호
    //           equipmentName: item.equipmentName,
    //           equipmentTypeName: item.equipmentTypeName,
    //           unitName: item.unitName,
    //           requestCount: item.requestCount,  // 대여 신청 장비 갯수
    //           rentCount: item.requestCount,  // 대여 장비 갯수
    //           rentStartDate: item.requestRentStartDate,  // 대여 시작 일자
    //           rentEndDate: item.requestRentEndDate,  // 대여 종료 일자
    //           rentPeriod: [item.requestRentStartDate, item.requestRentEndDate],
    //           returnDate: '',  // 반납일
    //           returnFlag: 'N',  // 반납여부
    //           remark: '',  // 비고
    //           regUserId: this.$store.getters.user.userId, 
    //           editFlag: 'C',
    //         })
    //       }
    //     })
    //   }
    // },
    rentInfo(row) {
      /**
       * 목적 : 선택된 행의 장비를 대여했음으로 표현
       * 유효성
       *  1. 대여수량
       *  2. 대여기간
       */
      if (!row.rentCount || row.rentCount === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '대여수량을 입력하세요.',
          type: 'error', // success / info / warning / error
        });
        return;
      } else if (!row.rentPeriod || row.rentPeriod.length !== 2 || !row.rentPeriod[0]) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '대여기간을 입력하세요.',
          type: 'error', // success / info / warning / error
        });
        return;
      }

      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '대여하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          row.rentFlag = 'Y'
          row.editFlag = 'U'
          row.chgUserId = this.$store.getters.user.userId
          this.$http.url = this.saveUrl;
          this.$http.type = 'PUT'; 
          this.$http.param = [row]
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    returnInfo(row) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '반납하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          row.returnFlag = 'Y'
          row.editFlag = 'U'
          row.chgUserId = this.$store.getters.user.userId
          this.$http.url = this.saveUrl;
          this.$http.type = 'PUT'; 
          this.$http.param = [row]
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveInfo() {
      if (this.$comm.validTable(this.gridRentReturn.columns, this.param.equipmentRent.rentReturnItems)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.param.equipmentRent.rentReturnItems, _item => {
              if (_item.rentPeriod && _item.rentPeriod.length > 0) {
                _item.rentStartDate = _item.rentPeriod[0]
                _item.rentEndDate = _item.rentPeriod[1]
              }
            })

            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    finishInfo() {
      if (this.$comm.validTable(this.gridRentReturn.columns, this.param.equipmentRent.rentReturnItems)) {
        // /**
        //  * 1. 대여완료하지 않은 항목이 있는지?
        //  * 2. 반납완료하지 않은 항목이 있는지?
        //  */
        // if (this.$_.findIndex(this.param.equipmentRent.rentReturnItems, { rentFlag: 'N' }) > -1) {
        //   window.getApp.$emit('ALERT', {
        //     title: '안내',
        //     message: '대여하지 않은 항목이 존재합니다.',
        //     type: 'error', // success / info / warning / error
        //   });
        //   return
        // } else if (this.$_.findIndex(this.param.equipmentRent.rentReturnItems, { returnFlag: 'N' }) > -1) {
        //   window.getApp.$emit('ALERT', {
        //     title: '안내',
        //     message: '반납완료하지 않은 항목이 존재합니다.',
        //     type: 'error', // success / info / warning / error
        //   });
        //   return
        // }
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '종료하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.param.equipmentRent.rentReturnItems, _item => {
              if (_item.rentPeriod && _item.rentPeriod.length > 0) {
                _item.rentStartDate = _item.rentPeriod[0]
                _item.rentEndDate = _item.rentPeriod[1]
              }
            })

            this.isFinish = !this.isFinish;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    finishCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('emitStep', { name: 'getDetail' });
    },
  },
};
</script>